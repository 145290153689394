<script setup>

</script>

<template>

  <svg width="128" height="35" viewBox="0 0 256 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_120_24380)">
      <path d="M165.236 47.007H161.369L156.436 69.711H161.3L162.068 65.704H164.408L165.166 69.711H170.11L165.236 47.007Z" fill="#CED0D0"/>
      <path d="M148.82 58.0319L153.285 47.0079H148.172L146.11 52.3099V47.0079H141.206V69.7129H146.11V64.1509L148.162 69.7129H153.625L148.82 58.0319Z" fill="#CED0D0"/>
      <path d="M134.777 54.144C134.568 52.829 134.299 51.773 133.93 50.904C133.552 49.998 133.082 49.261 132.534 48.732C131.996 48.205 131.399 47.796 130.76 47.537C130.149 47.2888 129.504 47.1338 128.847 47.077C128.28 47.0353 127.711 47.0119 127.142 47.007H123.026V69.714H125.967C126.813 69.714 127.612 69.682 128.339 69.634C129.095 69.5804 129.842 69.433 130.562 69.195C131.299 68.9528 131.975 68.5551 132.544 68.029C133.113 67.501 133.591 66.803 133.98 65.945C134.339 65.139 134.617 64.122 134.807 62.926C134.987 61.76 135.075 60.345 135.075 58.719C135.075 56.936 134.977 55.401 134.777 54.144ZM130.064 58.718C130.064 59.576 130.052 60.462 130.024 61.369C130.003 62.178 129.883 62.905 129.664 63.543C129.491 64.0627 129.169 64.52 128.738 64.858C128.528 65.028 128.239 65.148 127.871 65.208V51.522C128.379 51.6344 128.829 51.9285 129.136 52.349C129.376 52.679 129.554 53.077 129.674 53.526C129.814 54.053 129.914 54.612 129.973 55.19C130.033 55.788 130.063 56.396 130.063 56.994V58.717L130.064 58.718Z" fill="#CED0D0"/>
      <path d="M116.837 56.268C116.821 55.4543 116.761 54.6421 116.657 53.835C116.552 53.0049 116.379 52.1848 116.14 51.3829C115.905 50.5748 115.545 49.8085 115.073 49.1119C114.597 48.4076 113.966 47.8211 113.229 47.397C112.472 46.959 111.536 46.739 110.459 46.739C109.383 46.739 108.456 46.959 107.688 47.397C106.954 47.8206 106.324 48.4028 105.844 49.101C105.375 49.8031 105.015 50.5726 104.777 51.3829C104.538 52.1848 104.365 53.0049 104.26 53.835C104.16 54.643 104.1 55.4599 104.08 56.2579C104.061 57.0339 104.05 57.732 104.05 58.36C104.05 58.988 104.061 59.686 104.08 60.463C104.1 61.27 104.16 62.088 104.26 62.896C104.37 63.722 104.539 64.549 104.777 65.336C105.027 66.184 105.387 66.951 105.844 67.6189C106.329 68.3233 106.966 68.9091 107.709 69.333C108.475 69.762 109.402 69.991 110.459 69.991C111.515 69.991 112.443 69.761 113.209 69.333C113.951 68.9091 114.589 68.3232 115.073 67.6189C115.543 66.9174 115.903 66.1478 116.14 65.337C116.379 64.549 116.548 63.722 116.658 62.896C116.758 62.069 116.828 61.251 116.838 60.463C116.877 59.0649 116.876 57.666 116.837 56.268ZM111.774 61.401C111.724 62.307 111.626 63.094 111.465 63.751C111.325 64.33 111.136 64.779 110.907 65.087C110.788 65.247 110.667 65.297 110.46 65.297C110.25 65.297 110.13 65.247 110.01 65.087C109.78 64.779 109.592 64.33 109.453 63.752C109.303 63.094 109.193 62.307 109.143 61.401C109.083 60.463 109.063 59.436 109.063 58.36C109.063 57.284 109.083 56.268 109.143 55.32C109.193 54.413 109.303 53.626 109.453 52.968C109.592 52.39 109.781 51.9429 110.01 51.6329C110.13 51.4729 110.25 51.423 110.46 51.423C110.668 51.423 110.788 51.4729 110.907 51.6329C111.137 51.9429 111.326 52.4 111.465 52.968C111.626 53.626 111.725 54.413 111.774 55.32C111.834 56.287 111.854 57.304 111.854 58.36C111.854 59.417 111.834 60.4429 111.774 61.3999V61.401Z" fill="#CED0D0"/>
      <path d="M94.8511 47.007L93.4461 55.172L93.2171 56.576L91.7721 47.007H86.8381L91.2441 69.713H95.1711L99.6251 47.007H94.8511Z" fill="#CED0D0"/>
      <path d="M250.829 1.13696H239.456L234.572 32.721L234.503 33.17H244.13L244.679 27.23H245.436L246.024 33.17H255.642L250.829 1.13696Z" fill="#CED0D0"/>
      <path d="M225.672 1.34698H234.572V33.17H225.672V1.34698Z" fill="#CED0D0"/>
      <path d="M219.723 17.0439L225.183 1.34595H214.808L213.085 8.03395V1.34595H204.174V33.1699H213.084V26.4919L214.808 33.1699H225.633L219.723 17.0439Z" fill="#CED0D0"/>
      <path d="M199.27 1.13696H187.91L182.955 33.17H192.583L193.131 27.23H193.879L194.476 33.17H204.094L199.27 1.13696Z" fill="#CED0D0"/>
      <path d="M175.42 1.34595L174.703 10.6749L173.986 1.34595H164.129L169.062 33.1789H180.484L185.268 1.34595H175.42Z" fill="#CED0D0"/>
      <path d="M154.91 0.925964C145.373 0.925964 145.373 8.85996 145.373 17.261C145.373 25.663 145.373 33.596 154.91 33.596C164.459 33.596 164.459 25.663 164.459 17.261C164.459 8.85996 164.459 0.925964 154.91 0.925964Z" fill="#CED0D0"/>
      <path d="M101.398 0.925964C91.8611 0.925964 91.8611 8.85996 91.8611 17.261C91.8611 25.663 91.8611 33.596 101.398 33.596C110.947 33.596 110.947 25.663 110.947 17.261C110.947 8.85996 110.947 0.925964 101.398 0.925964Z" fill="#CED0D0"/>
      <path d="M146.986 31.6339C146.976 31.6139 146.299 30.2699 145.881 28.7539C145.411 27.0999 145.263 26.2139 145.263 26.2139L145.113 25.3259H138.773V1.34595H129.863V33.1699H147.763L146.986 31.6339Z" fill="#CED0D0"/>
      <path d="M122.917 13.226C122.837 13.166 122.747 13.096 122.638 13.026C121.971 12.569 120.585 11.612 120.585 10.466C120.585 9.80798 120.744 9.19998 121.86 9.19998C123.445 9.19998 124.401 9.38998 126.675 10.007L128.019 10.376V3.25998L127.951 2.49198L127.253 2.23298C125.13 1.44598 123.725 0.927979 121.223 0.927979C116.508 0.927979 111.466 3.37898 111.466 10.256C111.466 15.14 113.21 18.23 117.475 20.91C118.094 21.31 119.529 22.376 119.529 23.84C119.529 24.478 119.379 25.326 118.043 25.326C116.259 25.326 115.533 25.166 113.041 24.538L111.904 24.259L111.734 25.415C111.734 25.415 111.524 26.82 111.276 28.126C111.047 29.273 110.698 30.658 110.698 30.668L110.469 31.545L111.306 31.904C113.657 32.91 116.876 33.598 119.32 33.598C125.388 33.598 128.868 30.12 128.868 24.05C128.868 19.465 127.141 16.326 122.917 13.226Z" fill="#CED0D0"/>
      <path d="M81.7851 1.34595V13.0169H80.9381V1.34695H72.0281V33.1699H80.9381V20.8599H81.7851V33.1699H90.6951V1.34595H81.7851Z" fill="#CED0D0"/>
      <path d="M65.0909 8.98098C66.1469 8.98098 68.4299 9.07098 70.1539 9.19098L71.2899 9.27998V1.48598L70.3829 1.35598C68.4799 1.08698 65.9179 0.927979 64.5329 0.927979C52.9519 0.927979 52.9519 9.97798 52.9519 17.263C52.9519 25.336 54.4469 33.598 65.4789 33.598C66.7649 33.598 68.2799 33.458 70.3829 33.16L71.2899 33.03V25.246L70.1539 25.326C68.4499 25.456 66.1569 25.536 65.0909 25.536C62.6489 25.536 62.0709 25.236 62.0709 17.263C62.0709 9.28998 62.6489 8.98098 65.0909 8.98098Z" fill="#CED0D0"/>
      <path d="M52.4631 25.3259H44.9901V20.8609H51.7771V13.0169H44.9901V9.19995H52.2051V1.34595H36.0781V33.1699H53.7501L52.4631 25.3259Z" fill="#CED0D0"/>
      <path d="M28.3141 25.3259L34.7831 7.25595V1.34595H17.8101V9.19995H24.1391L17.8091 26.1229L18.0591 33.3089L35.3511 33.1589V25.3259H28.3141Z" fill="#CED0D0"/>
      <path d="M17.251 25.246L16.155 25.326C14.391 25.456 13.085 25.536 12.029 25.536C9.58702 25.536 9.01902 25.236 9.01902 17.263C9.01902 9.28998 9.58702 8.98098 12.029 8.98098C13.066 8.98098 14.371 9.06098 16.155 9.18998L17.291 9.27998V1.48598L16.384 1.35598C14.272 1.05698 12.767 0.927979 11.481 0.927979C7.95302 0.927979 5.40102 1.75498 3.69702 3.46898C0.827021 6.33898 0.827021 11.064 0.837021 16.525V17.263C0.837021 24.538 0.837021 33.598 12.417 33.598C13.863 33.598 14.75 33.528 16.584 33.259L17.531 33.129L17.251 25.246Z" fill="#CED0D0"/>
      <path d="M164.35 48.0779H162.187L157.712 68.6579H160.382L161.151 64.6529H165.246L166.014 68.6579H168.764L164.35 48.0779ZM161.558 62.5189L163.203 52.0649H163.253L164.289 59.5399L164.837 62.5189H161.558Z" fill="white"/>
      <path d="M145.014 58.2309L148.864 68.6609H152.004L147.637 58.0409L151.676 48.0759H148.864L145.014 57.9579V48.0759H142.231V68.6609H145.014V58.2309Z" fill="white"/>
      <path d="M133.702 54.3169C133.503 53.1009 133.244 52.1049 132.925 51.3269C132.596 50.5389 132.208 49.9329 131.759 49.4929C131.348 49.0802 130.86 48.752 130.323 48.5269C129.816 48.3151 129.277 48.1868 128.729 48.1469C128.189 48.1007 127.647 48.0776 127.105 48.0779H124.045V68.6579H125.928C126.755 68.6579 127.513 68.6389 128.221 68.5789C128.891 68.5302 129.553 68.4034 130.194 68.2009C130.787 68.0011 131.33 67.678 131.789 67.2529C132.247 66.8249 132.646 66.2469 132.975 65.5189C133.304 64.7929 133.553 63.8759 133.722 62.7589C133.901 61.6519 133.98 60.3079 133.98 58.7219C133.98 56.9989 133.892 55.5219 133.702 54.3169ZM131.091 58.7219C131.091 59.5989 131.081 60.4959 131.051 61.4129C131.021 62.3299 130.881 63.1569 130.633 63.8949C130.389 64.6062 129.947 65.2325 129.357 65.6989C128.769 66.1669 127.902 66.3769 126.776 66.3369V50.3999C127.542 50.3599 128.181 50.4599 128.699 50.7099C129.218 50.9579 129.636 51.2969 129.954 51.7359C130.274 52.1749 130.514 52.6819 130.673 53.2619C130.821 53.8479 130.932 54.4559 130.991 55.0939C131.061 55.7319 131.091 56.3699 131.091 57.0079V58.7219Z" fill="white"/>
      <path d="M115.752 56.29C115.731 55.5131 115.671 54.7378 115.572 53.967C115.475 53.1983 115.316 52.4388 115.096 51.696C114.891 50.9907 114.577 50.3215 114.167 49.712C113.772 49.1435 113.259 48.6676 112.662 48.317C112.065 47.978 111.318 47.799 110.42 47.799C109.534 47.799 108.787 47.979 108.188 48.317C107.59 48.6654 107.076 49.1417 106.683 49.712C106.274 50.3214 105.961 50.9907 105.757 51.696C105.537 52.433 105.377 53.191 105.277 53.967C105.179 54.757 105.117 55.533 105.099 56.29C105.079 56.9808 105.069 57.6718 105.068 58.363C105.068 58.983 105.078 59.679 105.098 60.445C105.118 61.204 105.178 61.9819 105.278 62.7589C105.378 63.5469 105.536 64.304 105.757 65.042C105.975 65.778 106.284 66.4359 106.683 67.0239C107.074 67.5964 107.593 68.0705 108.198 68.409C108.805 68.759 109.553 68.938 110.42 68.938C111.297 68.938 112.035 68.758 112.654 68.409C113.257 68.0678 113.774 67.5942 114.167 67.0239C114.567 66.4359 114.875 65.778 115.096 65.042C115.317 64.2953 115.477 63.5318 115.572 62.7589C115.672 61.9819 115.732 61.204 115.752 60.445C115.763 59.679 115.772 58.982 115.772 58.363C115.772 57.747 115.763 57.058 115.752 56.29ZM112.802 61.463C112.742 62.438 112.632 63.287 112.453 64.005C112.283 64.723 112.035 65.301 111.716 65.729C111.408 66.1569 110.968 66.368 110.42 66.368C109.882 66.368 109.443 66.158 109.125 65.728C108.805 65.301 108.566 64.723 108.387 64.005C108.217 63.287 108.107 62.438 108.048 61.463C107.995 60.4305 107.969 59.3968 107.968 58.363C107.968 57.277 107.999 56.242 108.048 55.263C108.108 54.297 108.218 53.45 108.387 52.733C108.567 52.016 108.805 51.436 109.125 51.009C109.443 50.579 109.882 50.37 110.42 50.37C110.968 50.37 111.408 50.58 111.716 51.01C112.035 51.436 112.284 52.016 112.453 52.732C112.633 53.45 112.743 54.297 112.802 55.264C112.909 57.3292 112.909 59.3977 112.802 61.463Z" fill="white"/>
      <path d="M94.262 68.6599L98.303 48.0759H95.709L94.453 55.3379L93.361 62.2719L93.224 63.5569H93.171L90.822 48.0759H88.092L92.078 68.6599H94.262Z" fill="white"/>
      <path d="M243.722 26.171H246.392L246.991 32.111H254.415L249.921 2.19196H240.373L235.739 32.112H243.174L243.722 26.171ZM244.778 13.016L244.998 10.046H245.167L245.376 13.016L245.884 20.65H244.27L244.778 13.016Z" fill="white"/>
      <path d="M226.733 2.40497H233.523V32.109H226.733V2.40497Z" fill="white"/>
      <path d="M212.028 19.804H212.452L215.635 32.11H224.122L218.606 17.046L223.698 2.40499H215.635L212.452 14.712H212.028V2.40399H205.238V32.109H212.028V19.804Z" fill="white"/>
      <path d="M192.174 26.171H194.846L195.444 32.111H202.869L198.363 2.19196H188.815L184.192 32.112H191.616L192.174 26.171ZM193.232 13.016L193.441 10.046H193.611L193.829 13.016L194.337 20.65H192.723L193.232 13.016Z" fill="white"/>
      <path d="M179.575 32.12L184.045 2.40601H176.406L175.006 20.652L174.793 24.047H174.623L174.411 20.652L173.011 2.40601H165.372L169.974 32.12H179.575Z" fill="white"/>
      <path d="M154.92 32.53C163.412 32.53 163.412 25.743 163.412 17.262C163.412 8.93999 163.412 1.98199 154.92 1.98199C146.428 1.98199 146.428 8.93899 146.428 17.262C146.428 25.743 146.428 32.53 154.92 32.53ZM154.92 7.71399C156.195 7.71399 156.404 8.76999 156.404 17.262C156.404 25.743 156.195 26.81 154.92 26.81C153.644 26.81 153.434 25.743 153.434 17.262C153.434 8.76999 153.644 7.71399 154.92 7.71399Z" fill="white"/>
      <path d="M144.86 29.041C144.373 27.291 144.222 26.381 144.222 26.381H137.718V2.40497H130.929V32.11H146.045C146.045 32.11 145.313 30.666 144.86 29.041Z" fill="white"/>
      <path d="M127.81 24.048C127.81 19.804 126.283 17.003 122.293 14.075C121.657 13.608 119.534 12.378 119.534 10.468C119.534 8.77098 120.595 8.13398 121.868 8.13398C123.566 8.13398 124.627 8.34598 126.961 8.98298V3.25398C124.796 2.44798 123.566 1.97998 121.231 1.97998C116.989 1.97998 112.532 4.10398 112.532 10.256C112.532 14.923 114.272 17.639 118.049 20.016C119.322 20.822 120.595 22.138 120.595 23.836C120.595 25.533 119.535 26.381 118.049 26.381C116.139 26.381 115.331 26.199 112.785 25.563C112.785 25.563 112.575 26.996 112.316 28.325C112.141 29.197 111.944 30.0646 111.726 30.927C113.948 31.884 116.989 32.534 119.322 32.534C124.415 32.534 127.81 29.988 127.81 24.048Z" fill="white"/>
      <path d="M101.4 32.53C109.891 32.53 109.891 25.743 109.891 17.262C109.891 8.93999 109.891 1.98199 101.4 1.98199C92.917 1.98199 92.917 8.93899 92.917 17.262C92.917 25.743 92.917 32.53 101.4 32.53ZM101.4 7.71399C102.675 7.71399 102.895 8.76999 102.895 17.262C102.895 25.743 102.675 26.81 101.4 26.81C100.133 26.81 99.925 25.743 99.925 17.262C99.925 8.76999 100.133 7.71399 101.4 7.71399Z" fill="white"/>
      <path d="M79.8798 19.804H82.8498V32.11H89.6398V2.40499H82.8498V14.074H79.8798V2.40399H73.0898V32.109H79.8798V19.804Z" fill="white"/>
      <path d="M65.484 32.534C66.885 32.534 68.454 32.364 70.237 32.11V26.38C68.497 26.508 66.197 26.593 65.095 26.593C61.403 26.593 61.02 24.811 61.02 17.257C61.02 9.70398 61.403 7.92198 65.095 7.92198C66.198 7.92198 68.497 8.00698 70.237 8.13398V2.40498C68.454 2.14998 65.944 1.97998 64.542 1.97998C54.656 1.97998 54.019 8.81298 54.019 17.257C54.019 25.702 55.597 32.534 65.484 32.534Z" fill="white"/>
      <path d="M51.5698 26.381H43.9318V19.804H50.7218V14.074H43.9318V8.13397H51.1458V2.40497H37.1418V32.11H52.5118L51.5698 26.381Z" fill="white"/>
      <path d="M18.88 26.298L19.087 32.242L34.294 32.109V26.381H26.812L33.729 7.07397V2.40497H18.876V8.13397H25.666L18.88 26.298Z" fill="white"/>
      <path d="M12.424 32.534C13.824 32.534 14.656 32.466 16.438 32.212L16.235 26.381C14.495 26.508 13.138 26.593 12.035 26.593C8.34296 26.593 7.95996 24.811 7.95996 17.257C7.95996 9.70398 8.34296 7.92198 12.034 7.92198C13.138 7.92198 14.495 8.00698 16.235 8.13398V2.40498C14.453 2.14998 12.883 1.97998 11.482 1.97998C1.59496 1.97998 1.89996 8.81298 1.89996 17.257C1.89996 25.702 2.53596 32.534 12.424 32.534Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_120_24380">
        <rect width="256" height="70" fill="white"/>
      </clipPath>
    </defs>
  </svg>


</template>

<style scoped>


</style>