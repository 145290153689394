<template>
  <div v-bind:class="{
    'modal-backdrop': true,
     'd-block': isOpen,
      'd-none': !isOpen,
    }" @click="closeModalIfBackdrop($event)">
    <div v-bind:class="{ 'modal': true, 'd-flex': isOpen }">
      <div class="modal-dialog modal-size-large">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">{{ title }}</div>
            <div @click="closeModal()" class="pointer close-button">
              X
            </div>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 9999999;
  height: 100%;
}

.modal-content {
  border-radius: 1.25rem;
  width: 600px!important;
  background: #282828;
  color: #FF6363;
}

.pointer {
  cursor: pointer;
}


.modal-header {
  border: 0;
}

.modal-title {

  text-align: center;
  //font-family: $font-bold;
  width: 100%;
  //padding-top: $spacing-1;
}

.close-button {
  position: absolute;
  top: 3%;
  right: 6%;
}

.modal-dialog {
  max-width: none;
  margin: 1.75rem auto;
}

@media (min-width: 768px) {
  .modal-body {
    padding: 2rem 3rem 3.5rem 3rem;
  }

  .close-button {
    position: absolute;
    top: 4%;
    right: 6%;
  }

}

@media (max-width: 768px) {
  .modal {

    .modal-dialog {
      max-width: none !important;
      width: 100% !important;
      height: 100vh !important;
      margin: 0;
    }

    .modal-content {
      border-radius: 0;
      height: 100vh !important;
    }

    .modal-body {
      height: 100%;
      overflow: auto;

    }
  }
}
</style>
<script>
export default {
  name: 'ModalComponent',
  components: {
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    closeModalIfBackdrop(event) {
      if (event.target.classList.contains('modal')) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>