import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
    .use(store)
    .use(router)
    .use(VueReCaptcha, {
        siteKey: '6Le3pjUpAAAAAM7aXcSXFv8nvAYUormZRv_H1jel',
        loaderOptions: {
            autoHideBadge: true
        }
    })
    .mount("#app");

//Recaptcha V3 API SECRET -> 6Le3pjUpAAAAAIas7z1DQv-YhJg8t1JnCFGO1crR