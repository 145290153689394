<template>
  <div ref="circle" class="circle d-none d-md-block"
       v-bind:style="{top: circleY + $store.state.scrollTop + 'px', left: circleX + 'px'}">
  </div>
</template>

<script>
export default {
  data() {
    return {
      circleX: 100,
      circleY: 100,
    };
  },
  methods: {
    handleMouseMove(event) {
      this.circleX = event.clientX;
      this.circleY = event.clientY;
    },
    handleDivClick(event) {
      event.stopPropagation();
    },
  },
  mounted() {
    document.body.addEventListener("mousemove", this.handleMouseMove);
    this.$refs.circle.addEventListener("click", this.handleDivClick);
  },
  beforeUnmount() {
    document.body.removeEventListener("mousemove", this.handleMouseMove);
    this.$refs.circle.removeEventListener("click", this.handleDivClick);
  },
};
</script>

<style scoped>
.circle {
  position: fixed;
  width: 200px;
  height: 200px;
  border: 0;
  border-radius: 100px;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: #FF6363;
  filter: blur(80px);
  transition: 0.15s;


}
</style>
