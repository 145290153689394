import { createStore } from "vuex";

export default createStore({
  state: {
    scrollTop: 0
  },
  getters: {},
  mutations: {
    setScrollTop (state, value) {
      state.scrollTop = value;
    },
  },
  actions: {},
  modules: {},
});
