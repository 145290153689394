<script setup>

</script>

<template>

  <svg width="100" height="47" viewBox="0 0 201 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.3404 53.7778C33.3404 44.6209 33.3404 35.4994 33.3404 26.3426C30.3367 32.9185 27.3683 39.5298 24.4 46.1058C22.3504 46.6008 20.3008 47.0604 18.2512 47.5907C15.2829 42.4289 12.3146 37.2318 9.31086 32.07C9.31086 41.2269 9.31086 50.3837 9.31086 59.5406C6.30718 60.3891 3.30349 61.273 0.299805 62.1922C0.299805 46.4947 0.299805 30.7972 0.299805 15.0998C3.23282 14.2159 6.20117 13.332 9.16951 12.4835C13.2333 19.908 17.2618 27.3678 21.2903 34.8277C25.2834 25.4233 29.3472 16.0543 33.4464 6.68537C36.4501 6.08434 39.4537 5.51866 42.4928 4.98834C42.4928 20.6858 42.4928 36.3833 42.4928 52.0807C39.4184 52.6111 36.3794 53.1767 33.3404 53.7778Z" fill="white"/>
    <path d="M72.8121 1.16992C70.3738 1.38205 67.9709 1.59418 65.5326 1.84166C59.7373 18.2462 54.0126 34.6861 48.3232 51.1614C51.539 50.6664 54.7193 50.2068 57.935 49.8179C58.8892 46.9188 67.3701 21.2867 69.3844 15.3825C71.3279 20.9332 79.5262 45.045 80.445 47.7674C83.6607 47.5906 86.9118 47.4492 90.1275 47.3431C84.4028 31.8931 78.6075 16.5138 72.8121 1.16992Z" fill="white"/>
    <path d="M110.199 8.38226C110.199 21.3574 110.199 34.2972 110.199 47.2724C107.09 47.2017 104.015 47.131 100.906 47.131C100.906 34.1558 100.906 21.216 100.906 8.24084C96.7711 8.24084 92.6013 8.31155 88.4668 8.45297C88.4668 5.73066 88.4668 2.973 88.4668 0.250687C99.8808 -0.10286 111.259 0.0739136 122.638 0.781007C122.638 3.50332 122.638 6.26098 122.638 8.98329C118.468 8.73581 114.334 8.52368 110.199 8.38226Z" fill="white"/>
    <path d="M130.165 48.4392C130.165 32.7417 130.165 17.0443 130.165 1.3468C140.59 2.30138 150.979 3.71556 161.297 5.55401C161.297 8.27632 161.297 11.034 161.297 13.7563C154.018 12.4482 146.738 11.3875 139.423 10.5037C139.423 14.1805 139.423 17.8574 139.423 21.5343C145.643 22.2768 151.862 23.1606 158.046 24.2213C158.046 26.9436 158.046 29.7012 158.046 32.4235C151.862 31.3629 145.643 30.479 139.423 29.7366C139.423 33.5549 139.423 37.3732 139.423 41.1915C146.738 42.0754 154.018 43.1714 161.297 44.4441C161.297 47.1664 161.297 49.9241 161.297 52.6464C150.979 50.808 140.59 49.4291 130.165 48.4392Z" fill="white"/>
    <path d="M183.1 57.7727C176.492 56.1111 170.449 53.1059 166.032 47.732C168.011 46.1411 169.955 44.5501 171.969 42.9945C174.831 46.4946 179.001 48.5098 183.242 49.6058C186.422 50.419 191.546 50.525 191.475 45.9643C191.44 44.338 191.016 42.7824 190.026 41.6157C189.108 40.5197 188.083 39.7419 185.927 38.858C184.054 38.1155 182.181 37.3731 180.309 36.666C176.916 35.3579 173.594 33.5194 171.262 30.6911C168.965 27.9334 167.905 24.3272 167.905 20.7211C167.905 11.4935 176.139 8.09944 184.16 10.15C190.132 11.6703 195.327 14.8875 199.108 19.8018C197.2 21.1453 195.292 22.5241 193.419 23.8676C191.016 20.8271 187.553 19.024 183.913 18.0695C180.662 17.1856 176.669 18.2816 176.704 22.2767C176.704 23.4434 177.093 24.8222 178.082 25.9535C179.001 27.0142 180.521 28.1102 182.393 28.8526C184.231 29.5951 186.069 30.3022 187.871 31.08C191.228 32.4234 194.444 34.368 196.705 37.1963C199.179 40.3075 200.239 44.338 200.274 48.2623C200.274 58.0202 190.839 59.7172 183.1 57.7727Z" fill="white"/>
    <path d="M79.6326 85.5614L84.7212 92.5617H81.6822L76.9823 85.8443H76.5229V92.5617H74.0493V76.1571H76.947C79.1026 76.1571 80.6928 76.5814 81.6469 77.3945C82.707 78.3137 83.2371 79.5158 83.2371 81.0007C83.2371 82.1674 82.919 83.1573 82.2476 84.0058C81.5762 84.819 80.6928 85.3493 79.6326 85.5614ZM76.5229 83.6876H77.3004C79.6326 83.6876 80.7988 82.8038 80.7988 81.0007C80.7988 79.339 79.668 78.4905 77.371 78.4905H76.4876V83.6876H76.5229Z" fill="white"/>
    <path d="M91.5766 76.1571V86.0564C91.5766 87.4706 91.824 88.4959 92.2834 89.1323C92.9548 90.0868 93.9443 90.5464 95.1811 90.5464C96.4179 90.5464 97.4073 90.0868 98.0788 89.1323C98.5381 88.4959 98.7855 87.4706 98.7855 86.0564V76.1571H101.259V86.7281C101.259 88.4605 100.729 89.8747 99.6336 91.0061C98.4321 92.2435 96.9479 92.8799 95.1811 92.8799C93.4142 92.8799 91.93 92.2435 90.7285 91.0061C89.6684 89.8747 89.103 88.4605 89.103 86.7281V76.1571H91.5766Z" fill="white"/>
    <path d="M106.772 92.5617L110.235 74.9197L115.854 87.6828L121.684 74.9197L124.794 92.5617H122.25L120.66 82.6624L115.818 93.3042L111.118 82.6271L109.352 92.5264H106.772V92.5617Z" fill="white"/>
  </svg>

</template>

<style scoped>


</style>