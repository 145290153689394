<script setup>

</script>

<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8433 5.60195H16V2.43372H13.4107C12.2632 2.43511 11.1631 2.89642 10.3518 3.71645C9.54037 4.53649 9.08393 5.64829 9.08255 6.80799V8.77018H7V11.9384H9.08986V22.4337H12.2247V11.9384H15.3814L16 8.77018H12.2247V6.22609C12.2271 6.06114 12.2931 5.90366 12.4086 5.78711C12.5241 5.67056 12.68 5.60413 12.8433 5.60195Z" fill="white" fill-opacity="0.35"/>
  </svg>

</template>

<style scoped>

</style>