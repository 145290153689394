<script setup>

</script>

<template>

  <svg width="80" height="60" viewBox="0 0 161 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4002 110.131C11.1002 110.131 7.70017 106.631 7.70017 102.331C7.70017 101.231 7.90017 100.131 8.40017 99.131C9.10017 97.431 10.5002 96.031 12.2002 95.331C14.6002 94.331 17.3002 94.531 19.5002 95.931C20.9002 96.831 22.8002 96.631 23.9002 95.531L25.6002 93.831C26.4002 93.031 26.4002 91.831 25.6002 91.031C25.6002 91.031 25.6002 91.031 25.5002 90.931C20.7002 86.731 13.8002 85.831 8.10017 88.731C5.10017 90.131 2.80017 92.531 1.40017 95.531C-3.69983 106.831 4.50017 117.931 15.3002 117.931C20.6002 117.931 25.5002 115.231 28.3002 110.731L22.1002 106.231C20.7002 108.631 18.2002 110.131 15.4002 110.131Z" fill="white"/>
    <path d="M131.8 113.431L131.2 87.031L117.7 101.431C117 102.131 115.8 102.231 115.1 101.531L115 101.431L101.5 87.031L100.9 113.431C100.9 113.431 100.9 113.431 100.9 113.531C101 115.631 102.8 117.131 104.9 117.031C106.8 116.931 108.3 115.431 108.4 113.531V107.631C108.4 106.931 109 106.231 109.8 106.231C110.2 106.231 110.6 106.431 110.9 106.731L116.5 113.531L122.1 106.731C122.6 106.131 123.4 106.031 124 106.531C124.3 106.831 124.5 107.231 124.5 107.631V113.431C124.6 115.531 126.4 117.031 128.5 116.931C130.2 116.931 131.7 115.431 131.8 113.431Z" fill="white"/>
    <path d="M157.4 110.631H148.8C148 110.631 147.3 109.931 147.3 109.131C147.3 108.331 148 107.631 148.8 107.631H155.2C157 107.531 158.4 106.131 158.3 104.331C158.2 102.631 156.9 101.231 155.2 101.231H151C149.4 101.231 147.9 100.231 147.5 98.631C147 96.831 148.2 94.931 150 94.431C150.3 94.331 150.6 94.331 150.8 94.331H156.7C157.8 94.431 158.9 93.831 159.4 92.931C160.4 91.431 159.9 89.331 158.4 88.431C157.9 88.131 157.3 87.931 156.7 87.931H143.7H143.2C141.4 87.931 140 89.331 140 91.131V114.531C140.3 116.031 141.5 117.031 142.9 117.231H157.4C159.2 117.231 160.6 115.731 160.6 113.931C160.6 112.031 159.2 110.631 157.4 110.631Z" fill="white"/>
    <path d="M49.3002 117.331C40.7002 117.331 33.7002 110.631 33.7002 102.331C33.7002 94.031 40.7002 87.331 49.3002 87.331C57.9002 87.331 64.9002 94.031 64.9002 102.331C64.9002 110.631 57.9002 117.331 49.3002 117.331ZM49.3002 95.031C44.9002 95.031 41.4002 98.331 41.4002 102.331C41.4002 106.331 44.9002 109.631 49.3002 109.631C53.7002 109.631 57.2002 106.331 57.2002 102.331C57.2002 98.331 53.7002 95.031 49.3002 95.031Z" fill="white"/>
    <path d="M88.5002 101.431C86.5002 100.231 84.4002 99.331 82.2002 98.531C82.2002 98.531 79.9002 97.931 79.9002 96.331C79.9002 95.131 80.7002 93.931 83.1002 93.931C84.1002 93.931 85.0002 94.231 85.9002 94.731C86.4002 95.031 87.0002 95.231 87.5002 95.231C88.5002 95.231 89.4002 94.831 90.1002 94.131L91.0002 93.231C91.6002 92.531 91.6002 91.431 90.9002 90.831C90.9002 90.831 90.9002 90.831 90.8002 90.731C88.4002 88.531 85.2002 87.331 81.9002 87.331C76.7002 87.331 71.9002 90.631 71.9002 96.331C71.9002 99.131 73.4002 101.731 75.8002 103.231C76.7002 103.731 78.1002 104.431 79.2002 104.931C80.7002 105.631 83.9002 106.831 84.2002 106.931C85.0002 107.231 85.5002 107.931 85.5002 108.731C85.5002 112.231 79.5002 110.731 79.5002 110.731C77.9002 110.231 76.4002 109.431 75.2002 108.231L70.2002 111.931C71.6002 113.231 76.4002 117.831 83.0002 117.831C88.1002 117.831 92.4002 115.731 93.4002 110.331C94.4002 104.031 88.5002 101.431 88.5002 101.431Z" fill="white"/>
    <path d="M160.5 35.331C160.5 32.231 158.4 29.231 153.4 29.731C150.3 30.031 147.7 32.231 147.7 35.331C147.7 35.431 147.7 35.431 147.7 35.531V48.831C147.7 51.331 146.3 52.631 144.3 51.731L115 28.831C115 29.731 115.1 75.531 115.1 75.531C115.1 78.631 117.9 81.131 122.7 81.131C125.8 81.131 128.4 78.631 128.4 75.531V60.231C128.5 58.931 129.6 57.931 130.9 58.031C131.3 58.031 131.7 58.131 132 58.331L160.6 81.031L160.5 35.331Z" fill="white"/>
    <path d="M100.5 73.731L78.3 28.231L56.1 73.731C55.5 75.031 55.3 76.431 55.8 77.831C56.3 79.231 57.4 80.531 60 80.731C60 80.731 65.6 81.731 68.3 78.031C69.1 76.831 70.4 76.131 71.8 76.131H77.6H79.3H85C86.4 76.131 87.7 76.831 88.5 78.031C91.2 81.731 96.8 80.731 96.8 80.731C99.4 80.531 100.5 79.231 101 77.831C101.3 76.431 101.2 74.931 100.5 73.731ZM78.3 66.031C76.5 66.031 75.3 64.231 75.3 62.331C75.3 60.431 76.4 57.231 78.2 57.231H78.3C80 57.231 81.3 60.431 81.3 62.331C81.3 64.231 80.1 66.031 78.3 66.031Z" fill="white"/>
    <path d="M34.9002 52.531C31.4002 50.531 27.8002 48.831 24.0002 47.531C24.0002 47.531 20.0002 46.431 19.9002 43.831C19.8002 41.831 21.4002 39.631 25.4002 39.631C27.1002 39.731 28.8002 40.131 30.2002 41.031C31.0002 41.531 32.0002 41.831 33.0002 41.831C34.7002 41.831 36.3002 41.131 37.5002 39.931L39.0002 38.331C40.1002 37.131 40.1002 35.331 38.9002 34.231L38.8002 34.131C34.7002 30.231 29.2002 28.131 23.5002 28.231C14.5002 28.231 6.2002 33.831 6.2002 43.831C6.2002 48.731 8.7002 53.231 12.9002 55.731C14.4002 56.531 16.8002 57.831 18.8002 58.731C21.3002 59.931 27.0002 62.031 27.4002 62.231C28.9002 63.031 29.7002 63.931 29.7002 65.431C29.7002 71.531 19.3002 68.931 19.3002 68.931C16.5002 68.131 14.0002 66.631 11.9002 64.631L3.2002 71.131C5.6002 73.431 13.9002 81.331 25.3002 81.331C34.1002 81.331 41.6002 77.731 43.2002 68.331C45.0002 57.131 34.9002 52.531 34.9002 52.531Z" fill="white"/>
    <path d="M60.2003 10H58.4003V4.8L55.3003 7.9L52.1003 4.8V10H50.3003V0.5L55.2003 5.4L60.1003 0.5V10H60.2003Z" fill="white"/>
    <path d="M70.0005 0.900024V2.70002H64.7005V4.60002H68.4005V6.40002H64.7005V8.30002H70.0005V10H63.0005V0.900024H70.0005Z" fill="white"/>
    <path d="M80.5003 0.900024L75.9003 8.30002H80.3003V10H72.8003L77.4003 2.60002H73.2003V0.900024H80.5003Z" fill="white"/>
    <path d="M87.1001 10C85.8001 10 84.8001 9.6 83.9001 8.7C83.0001 7.8 82.6001 6.7 82.6001 5.5C82.6001 4.2 83.0001 3.2 83.9001 2.3C84.8001 1.4 85.9001 1 87.1001 1C88.4001 1 89.4001 1.4 90.3001 2.3L89.1001 3.5C88.6001 3 87.9001 2.7 87.1001 2.7C86.3001 2.7 85.7001 3 85.1001 3.5C84.5001 4 84.3001 4.7 84.3001 5.5C84.3001 6.3 84.6001 6.9 85.1001 7.5C85.6001 8 86.3001 8.3 87.1001 8.3C87.9001 8.3 88.5001 8 89.1001 7.5L90.3001 8.7C89.4001 9.6 88.3001 10 87.1001 10Z" fill="white"/>
    <path d="M101.6 10H99.7001L99.2001 8.9H95.5001L95.0001 10H93.1001L97.3001 0.5L101.6 10ZM96.3001 7.2H98.4001L97.3001 4.8L96.3001 7.2Z" fill="white"/>
    <path d="M106.2 0.900024V8.30002H111.6V10H104.4V0.900024H106.2Z" fill="white"/>
  </svg>

</template>

<style scoped>


</style>