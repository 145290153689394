<script setup>

</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_104_24372)">
      <path d="M10.0072 0.698686C11.619 0.698686 13.2308 0.691258 14.8501 0.698686C16.9001 0.713541 18.6976 2.05795 19.277 4.01886C19.4032 4.44966 19.4849 4.91761 19.4849 5.36327C19.4998 8.57945 19.5072 11.7882 19.4924 15.0044C19.4775 17.5967 17.4126 19.6615 14.8129 19.6764C11.6042 19.6913 8.39543 19.6913 5.18667 19.6764C2.57955 19.6615 0.51466 17.5967 0.507232 14.9895C0.492377 11.7882 0.492377 8.58688 0.507232 5.38555C0.51466 3.01612 2.2156 1.08493 4.55532 0.743252C4.80786 0.706114 5.07525 0.698686 5.3278 0.698686C6.89504 0.691258 8.45485 0.691258 10.0072 0.698686C10.0072 0.691258 10.0072 0.691258 10.0072 0.698686ZM10.0072 2.59274C8.42514 2.59274 6.84304 2.58532 5.26095 2.59274C3.62686 2.60017 2.40872 3.81831 2.40872 5.44497C2.40129 8.60916 2.40129 11.7734 2.40872 14.9375C2.41615 16.5494 3.61943 17.7749 5.23866 17.7823C8.41771 17.7972 11.5893 17.7898 14.7684 17.7823C16.3802 17.7749 17.5983 16.5642 17.5983 14.945C17.6057 11.7734 17.6057 8.60173 17.5983 5.43012C17.5983 4.9696 17.5092 4.53137 17.2938 4.12285C16.7516 3.09783 15.8751 2.60017 14.7312 2.58532C13.1566 2.57789 11.5819 2.59274 10.0072 2.59274ZM14.7461 10.1913C14.7461 12.8504 12.6515 14.945 9.98495 14.9375C7.33327 14.9301 5.24609 12.8429 5.24609 10.1838C5.24609 7.52472 7.3407 5.43012 10.0072 5.43754C12.6663 5.44497 14.7461 7.53958 14.7461 10.1913ZM12.852 10.1764C12.8446 8.57202 11.6042 7.33903 9.9998 7.3316C8.388 7.3316 7.14758 8.57945 7.14758 10.1913C7.155 11.7956 8.39543 13.0361 9.9998 13.0361C11.6116 13.0361 12.8595 11.7882 12.852 10.1764ZM14.7535 4.49423C14.2336 4.49423 13.7953 4.92503 13.7953 5.4524C13.7953 5.97234 14.2262 6.39571 14.7387 6.39571C15.2586 6.40314 15.6968 5.96491 15.6968 5.43754C15.6968 4.91761 15.2735 4.49423 14.7535 4.49423Z" fill="#747578"/>
    </g>
    <defs>
      <clipPath id="clip0_104_24372">
        <rect width="19" height="19" fill="white" transform="translate(0.5 0.691162)"/>
      </clipPath>
    </defs>
  </svg>


</template>

<style scoped>

</style>