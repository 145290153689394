<script setup>

</script>

<template>
  <svg width="199" height="116" viewBox="0 0 199 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_177_6527)">
      <path d="M0 115.154V62.1467H11.2836V105.224H24.3346V115.154H0Z" fill="white"/>
      <path d="M76.022 26.7902C68.7895 26.7902 63.9498 32.7921 63.9498 39.7215C63.9498 46.7874 68.7895 52.8984 76.1307 52.8984C83.2272 52.8984 88.0125 46.6782 88.0125 39.8852C88.0397 33.0376 83.2544 26.7902 76.022 26.7902ZM75.9132 62.8833C63.4332 62.8833 52.6934 53.1712 52.6934 40.4035C52.6934 37.1025 53.2371 33.9924 54.4335 30.9369C55.086 29.1909 56.1736 27.4722 57.2884 25.9717C61.829 19.9699 68.4633 16.8598 75.9676 16.8598C88.8554 16.8598 99.3234 26.8175 99.3234 39.8579C99.3234 52.9802 88.8554 62.8833 75.9132 62.8833Z" fill="white"/>
      <path d="M128.226 62.0376L111.395 35.0837L111.259 35.1383L111.558 62.0376H100.764V17.7056H110.498L127.573 45.5598L127.682 45.4233L127.22 17.7056H138.014V62.0376H128.226Z" fill="white"/>
      <path d="M138.014 17.7056C138.014 17.7056 138.014 0 160.881 0C160.881 0 160.935 17.7056 138.014 17.7056Z" fill="white"/>
      <path d="M26.999 115.154V70.8495H51.524V80.8072H37.7933V87.682H51.0889V97.6124H37.7933V105.224H51.524V115.154H26.999Z" fill="white"/>
      <path d="M90.378 115.154L90.7315 82.0075L90.6227 81.8711L81.188 115.154H73.7381L64.3033 81.8711L64.1674 82.0075L64.548 115.154H54.1616V70.8495H68.6536L77.4358 100.013H77.5446L86.1909 70.8495H100.764V115.154H90.378Z" fill="white"/>
      <path d="M125.344 79.9615C118.111 79.9615 113.299 85.936 113.299 92.8928C113.299 99.9586 118.139 106.07 125.48 106.07C132.576 106.07 137.361 99.8495 137.361 93.0837C137.334 86.1816 132.576 79.9615 125.344 79.9615ZM125.235 116C112.755 116 102.015 106.315 102.015 93.5475C102.015 90.2465 102.559 87.1364 103.755 84.0809C104.408 82.3349 105.495 80.6162 106.61 79.1157C111.151 73.1138 117.758 70.0038 125.289 70.0038C138.177 70.0038 148.618 79.9342 148.618 93.0019C148.618 106.124 138.177 116 125.235 116Z" fill="white"/>
      <path d="M177.384 115.209L160.554 88.2822L160.418 88.3368L160.717 115.209H149.923V70.9041H159.657L176.705 98.7582L176.84 98.6218L176.351 70.9041H187.145V115.209H177.384Z" fill="white"/>
      <path d="M194.595 73.5231H193.943V74.9144H194.568C195.085 74.9144 195.357 74.6961 195.357 74.1778C195.384 73.6595 195.03 73.5231 194.595 73.5231ZM195.466 77.588C195.357 77.3697 195.33 76.6877 195.302 76.4149C195.221 75.8965 194.976 75.7056 194.432 75.7056H193.943V77.588H192.719V72.6773H194.786C195.656 72.6773 196.58 73.032 196.58 74.0687C196.58 74.6416 196.281 75.0235 195.792 75.2418C196.798 75.6783 196.336 76.7695 196.635 77.588H195.466ZM194.623 71.6406C192.665 71.6406 191.17 73.2502 191.17 75.1599C191.17 77.0696 192.692 78.6792 194.595 78.6792C196.526 78.6792 198.021 77.0696 198.021 75.1599C198.048 73.2775 196.553 71.6406 194.623 71.6406ZM194.623 79.6068C192.203 79.6068 190.245 77.6152 190.245 75.1872C190.245 72.7319 192.23 70.7676 194.65 70.7676C197.07 70.7676 199 72.7865 199 75.1872C199 77.6152 197.042 79.6068 194.623 79.6068Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_177_6527">
        <rect width="199" height="116" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped>


</style>