<template>
  <div class="coming-soon-container">
    <div class="">
      <div class="drinkplus-logo">
        <DrinkPlusLogo/>
      </div>
      <div class=" video-container d-md-none">
        <figure>
          <video playsinline autoplay muted loop>
            <source src="~@/assets/videos/mobile.mp4" type="video/mp4">
          </video>
        </figure>
      </div>
      <div class="coming-soon-title">Something special is unveiling soon!</div>
      <div class="container">
        <div class="brand-logos row ">
          <div class="one-logo col-md-1 col-3">
            <KarloffLogo/>
          </div>
          <div class="one-logo col-md-1 col-3">
            <TatrateaLogo/>
          </div>
          <div class="one-logo col-md-1 col-3">
            <CzechoslovakiaLogo/>
          </div>
          <div class="one-logo col-md-1 col-3">
            <DeadMansFingersLogo/>
          </div>
          <div class="one-logo col-md-1 col-4">
            <MatesLogo/>
          </div>
          <div class="one-logo col-md-1 col-4">
            <BerkshireLogo/>
          </div>
          <div class="one-logo col-md-1 col-4">
            <Gin1689Logo/>
          </div>
          <div class="one-logo col-md-1 col-4">
            <LalaTequilaLogo/>
          </div>
          <div class="one-logo col-md-1 col-4">
            <SanCosmeLogo/>
          </div>
          <div class="one-logo col-md-1 col-4">
            <PadreAzulLogo/>
          </div>
          <div class="one-logo col-md-1 col-12">
            <XiamanLogo/>
          </div>
          <div class="one-logo col-md-1 col-12">
            <OnLemonLogo/>
          </div>
        </div>
        <div class="coming-soon-subtitle">Unlock the Mystery</div>
        <div class="coming-soon-subtitle">Enter your email below and become an honored guardian of the secret.</div>
        <div class="input-form">
          <input v-model="email" type="email" name="text" class="input" placeholder="Your email">
          <div class="input-form-send-button" @click="subscribe">Send now</div>
        </div>
        <div class="contact-details d-md-flex text-center">
          <div class="contact-item">T: +359899008868</div>
          <div class="contact-item">E: office@drinkplus.bg</div>
        </div>
        <div class=" video-container d-none d-md-flex">
          <figure>
            <video playsinline autoplay muted loop>
              <source src="~@/assets/videos/desktop.mp4" type="video/mp4">
            </video>
          </figure>
        </div>
        <div class="d-md-none row">
          <a href="https://www.facebook.com/drinkplusbulgaria"
             class="col-6 justify-content-end justify-content-end d-flex">
            <FacebookLogo/>
          </a>
          <a href="http://instagram.com/drinkplus_bg/" class="col-6">
            <InstagramLogo/>
          </a>
        </div>
      </div>
    </div>

    <ModalComponent title="" :is-open="isModalOpen" @closeModal="isModalOpen = false">
      <div class="w-100 p-3 text-center">
        You have subscribed successfully!
      </div>
    </ModalComponent>

  </div>
</template>

<script setup>
import DrinkPlusLogo from "@/components/Common/Icons/DrinkPlusLogo.vue";
import BerkshireLogo from "@/components/Common/Icons/BerkshireLogo.vue";
import DeadMansFingersLogo from "@/components/Common/Icons/DeadMansFingersLogo.vue";
import Gin1689Logo from "@/components/Common/Icons/Gin1689Logo.vue";
import KarloffLogo from "@/components/Common/Icons/KarloffLogo.vue";
import CzechoslovakiaLogo from "@/components/Common/Icons/CzechoslovakiaLogo.vue";
import LalaTequilaLogo from "@/components/Common/Icons/LalaTequilaLogo.vue";
import MatesLogo from "@/components/Common/Icons/MatesLogo.vue";
import PadreAzulLogo from "@/components/Common/Icons/PadreAzulLogo.vue";
import SanCosmeLogo from "@/components/Common/Icons/SanCosmeLogo.vue";
import TatrateaLogo from "@/components/Common/Icons/TatrateaLogo.vue";
import XiamanLogo from "@/components/Common/Icons/XiamanLogo.vue";
import ModalComponent from "@/components//ModalComponent.vue";
import FacebookLogo from "@/components/Common/Icons/FacebookLogo.vue";
import InstagramLogo from "@/components/Common/Icons/InstagramLogo.vue";
import {useReCaptcha} from 'vue-recaptcha-v3'
import axios from "axios";
import {ref} from "vue";
import OnLemonLogo from "@/components/Common/Icons/OnLemonLogo.vue";

const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()
const email = ref(null);
const isModalOpen = ref(false);


async function subscribe() {
  const isMailValid = String(email.value)
      .toLowerCase()
      .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  if (email.value === null || isMailValid === null) {
    return;
  }
  await recaptchaLoaded()
  const token = await executeRecaptcha('login')
  try {
    await axios.post('https://intera.bg/api/mailing/subscribe', {
      app: 'drinkplus',
      token: token,
      email: email.value,
    });
    isModalOpen.value = true;
  } catch (err) {
    isModalOpen.value = true;
  } finally {
    email.value = null;
  }
}
</script>

<style>

@import "~@/assets/scss/fonts.scss";

.video-container {
  justify-content: center;

}

figure {
  width: 100%;
  height: 430px;
  position: absolute;
  background-color: transparent;
  overflow: hidden;
  margin: 0;
  top: 0%;
  left: 0%;


}

video {
  object-fit: cover;
  position: absolute;
  top: -8%;
  left: 26%;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #ccc;
  clip-path: polygon(
      404.684px 0.306885px, 144.923px 0.306885px, 102.701px 156.878px,
      22.233px 156.878px, 2.03955px 231.946px, 82.813px 231.946px,
      39.6726px 392.807px, 300.045px 392.807px, 419.064px 278.213px,
      464.04px 118.578px, 404.684px 0.306885px, 329.111px 255.539px,
      273.121px 311.611px, 155.326px 311.611px, 176.743px 231.946px,
      239.464px 231.946px, 259.658px 156.878px, 196.936px 156.878px,
      217.741px 80.2776px, 335.536px 80.2776px, 362.461px 135.736px,
      329.111px 255.539px
  );
  transform: scale(1.3);

}

.coming-soon-container {
  background-color: #292A30;
  background-image: url("~@/assets/images/coming-soon-background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 420px;
  padding-bottom: 100px;

}

.drinkplus-logo {
  display: flex;
  justify-content: center;

  position: relative;
  top: -23%;
  z-index: 99999;

}

.input-form-send-button {
  font-family: ClashDisplayBold;
  position: absolute;
  z-index: 100000;
  color: #FFFFFF;
  cursor: pointer;
  top: 1%;
  right: 50%;
  transform: translate(50%, -50%);
  top: 150%;

  &:hover {
    color: #FF6363;
    transition: 0.3s;
  }
}

.coming-soon-title {
  font-family: ClashDisplayBold;
  color: #D5D5D5;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 30px 0 25px;
  text-align: center;
}

.coming-soon-subtitle {
  color: #FFF;
  text-align: center;
  font-family: ClashDisplayLight;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.brand-logos {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 35px;
}

.one-logo {
  transform: scale(0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  opacity: 0.7;
}

.input-form {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  position: relative;
}

.input {

  padding: 20px;
  font-family: ClashDisplayRegular;
  font-weight: 500;

  color: #fff;
  width: 90%;
  background-color: #383A42;
  box-shadow: 0 0 0 .15vw #383A42;

  border-radius: 10px;
  border: none;
  outline: none;
  transition: .4s;
  z-index: 99999;
  position: relative;
}

.input:hover {
  box-shadow: 0 0 .4vw rgba(0, 0, 0, 0.5), 0 0 0 .15vw transparent;
}

.input:focus {
  box-shadow: 0 0 0 1px #FF6363;
}

.contact-details {
  color: #FFFFFF;
  font-family: ClashDisplayLight;
  padding: 55px;
  justify-content: center;

  .contact-item {
    margin: 10px;
  }
}


@media only screen and (min-width: 768px) {
  .coming-soon-container {
    padding: 50px 0 0;

  }

  .one-logo {
    margin: 0px 0;
  }

  .drinkplus-logo {
    position: relative;
    top: 0;
    left: 0;
  }

  .coming-soon-title {
    font-size: 61.755px;
  }

  .input {
    width: 60%;
    padding: 30px;
  }

  .input-form-send-button {
    top: 50%;
    right: 25%;
  }

  .contact-details {
    margin-top: 10px;
    padding: 25px;
  }

  figure {
    position: relative;
    top: 0%;
    left: 0;
    width: 1000px;
    height: 385px;
  }

  video {
    top: 0%;
    transform: scale(1);
    left: 0%;
    clip-path: polygon(
        809.367px 0.61377px, 289.846px 0.61377px, 205.401px 313.756px,
        44.4659px 313.756px, 4.0791px 463.892px, 165.626px 463.892px,
        79.3453px 785.614px, 600.09px 785.614px, 838.127px 556.426px,
        928.079px 237.156px, 809.367px 0.61377px, 658.222px 511.078px,
        546.241px 623.221px, 310.651px 623.221px, 353.486px 463.892px,
        478.929px 463.892px, 519.316px 313.756px, 393.872px 313.756px,
        435.483px 160.555px, 671.073px 160.555px, 724.922px 271.472px,
        658.222px 511.078px
    );
  }
}
</style>